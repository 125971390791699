<template>
  <input
    class="input"
    :class="auth"
    :placeholder="placeholder"
    @input="enterInput"
    @focus="focusInput"
    v-model="inputModal"
  >
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
    },
    auth: {
      type: String,
    }
  },
  data() {
    return {
      inputModal: ''
    }
  },
  methods: {
    enterInput() {
      this.$emit('enter-input-modal', this.inputModal)
    },
    focusInput() {
      this.$emit('focus-input')
    }
  },

}
</script>

<style lang="scss" scoped>
@import "input";
</style>