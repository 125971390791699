import * as API from '../../api/index'
import router from "@/router";

export default {
    namespaced: true,
    state() {
        return {
            faqList: []
        }
    },
    actions: {
        faqList({ commit }) {
            API.faqList().then(response => {
                commit('SET_FAQ_LIST', response)
            })
        },
    },
    mutations: {
        SET_FAQ_LIST(state, faqList) {
            state.faqList = faqList
        },
    },
    getters: {},
}
