<template>
  <div class="rw-tabs">
    <div
        v-for="tab in tabs"
        :key="tab.id"
        @click="openTab(tab)"
        :class="{ 'open': tab.id === selectedTab }"
        class="rw-tabs__tab"
    >
      <span>{{ tab.title }}</span>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
export default {
  data() {
    return {
      selectedTab: 0,
      tabs: [
        { title:  'Пополнить', link: '/dashboard/replenishment-withdrawal/deposit', id: 0},
        { title: 'Вывести', link: '/dashboard/replenishment-withdrawal/withdrawal', id: 1},
      ]
    }
  },
  computed:{
    ...mapGetters('locale', ['getTranslation'])
  },
  methods: {
    openTab(tab) {
      this.selectedTab = tab.id
      this.$router.push(tab.link)

      if(tab.id === 0) {
        this.$emit('open-replenishment')
      } else if(tab.id === 1) {
        this.$emit('open-withdrawal')
      }
    }
  },
  mounted() {
    const path = '/dashboard/replenishment-withdrawal/'
    switch (this.$route.path) {
      case `${path}deposit`:
        this.selectedTab = 0;
        break;
      case `${path}withdrawal`:
        this.selectedTab = 1;
        break;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "rWTabs";
</style>