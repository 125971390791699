import * as API from '../../api/index'

export default {
  namespaced: true,
  state() {
    return {
      fortuneSections: null,
      fortuneWinners: null,
      playWin: null,
      buyTicket: null,
    }
  },
  actions: {
    getFortuneSection({ state }) {
      API.getFortuneSection().then(response => {
        state.fortuneSections = response
      })
    },
    getFortuneWinners({ state }) {
      API.getFortuneWinners().then(response => {
        state.fortuneWinners = response.winners
      })
    },
    buyTicketFortune({ state }) {
      API.buyTicketFortune().then(response => {
        state.buyTicket = response
      })
    },
    playFortune({ state }) {
      API.playFortune().then(response => {
        state.playWin = response
      })
    }
  },
  mutations: {

  },
  getters: {

  },
}
