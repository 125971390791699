<template>
  <button class="button" :class="color">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: fit-content;
  height: 60px;
  border-radius: 12px;
  background: #2B76BA;
  padding: 22px 40px;
  color: #ECF3FF;
  font-family: "TTFirsNeue", sans-serif ;
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  text-align: center;
  transition: all 0.3s;

  &:hover {
    background: #13436b;
  }

  &.green {
    background: #3D9851;

    &:hover {
      background: #20592c;
    }
  }

  &.gray {
    background: #1F2A3D;
    color: #697383;
    height: 36px;
    border-radius: 4px;
    width: 100%;
    margin-top: auto;

    &:hover {
      background: #15212c;
    }
  }

  &.dark {
    background: #1E2C46;

    &:hover {
      background: #344364;
    }
  }

  & > img {
    width: 18px;
  }
}

@media (max-width: 1680px) {
  .button {
    border-radius: 10px;
    padding: 20px 36px;
    font-size: 15px;
    height: 58px;
  }
}


@media (max-width: 1440px) {
  .button {
    border-radius: 10px;
    padding: 18px 32px;
    font-size: 14px;
    height: 56px;

    & > img {
      width: 16px;
    }
  }
}

@media (max-width: 1280px) {
  .button {
    border-radius: 9px;
    padding: 16px 30px;
    font-size: 12px;
    height: 52px;

    & > img {
      width: 15px;
    }
  }
}

@media (max-width: 1140px) {
  .button {
    border-radius: 8px;
    padding: 14.5px 28px;
    font-size: 11px;
    height: 48px;
  }
}

@media (max-width: 992px) {
  .button {
    border-radius: 10px;
    padding: 22px 32px;
    font-size: 14px;
    height: 56px;

    & > img {
      width: 16px;
    }
  }
}
</style>