<template>
  <div class="tickets-user-room">
    {{getTranslation('Вы имеете')}}
    <span>{{ tickets }} {{ ticketsText }}</span>
    {{getTranslation('в данной лотереи')}}
  </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  props: {
    tickets: {}
  },
  computed: {
    ...mapGetters('locale', ['getTranslation']),
    ticketsText() {
      let count = this.tickets

      if (count % 100 >= 5 && count % 100 <= 20) {
        return this.getTranslation('билетов') ;
      }
      switch (count % 10) {
        case 1: return this.getTranslation('билет');
        case 2:
        case 3:
        case 4: return this.getTranslation('билета');
        default: return this.getTranslation('билетов');
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "ticketsUserRoom";
</style>