<template>
  <div class="loader"></div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.loader {
  border: 4px solid #0A121F;
  border-left-color: #2B76BA;
  border-bottom-color: #2B76BA;
  border-right-color: #2B76BA;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>