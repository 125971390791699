import axios from "axios";
import router from "../router/index.js";

const BASE_URL = process.env.VUE_APP_API_URL

function blockCatch(error) {

  const response = error?.response

  if (response) {
    store.commit('notifications/ERROR_CHANGE', response.data.message)
  }

  if (response?.status === 401) {
    router.push('/auth/authorization')
  }

}

import VueCookies from 'vue-cookies'
import store from "@/store";

function getCookieValue(cookieName) {
  return VueCookies.get(cookieName)
}


export async function postAsync(url, data, isFile) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "XSRF-TOKEN": getCookieValue('XSRF-TOKEN'),
    }
  }

  if (localStorage.getItem('authToken')) {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem('authToken')}`
  }


  isFile ? config.headers['Content-Type'] = "multipart/form-data" : config.headers['Content-Type'] = "application/json"

  try {
    let response = await axios.post(BASE_URL + url, data, config)

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      return response.data
    }

  } catch (error) {
    blockCatch(error)
  }

  return undefined
}

export async function getAsync(url, queryParams) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json"
    },
  }

  if (localStorage.getItem('authToken')) {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem('authToken')}`
  }

  queryParams ? config.params = queryParams : ''

  try {
    let response = await axios.get(BASE_URL + url, config)
    if (response.status === 200 || response.status === 201) {
      return response.data
    }
  } catch (error) {
    blockCatch(error)
  }
}

export async function deleteAsync(url) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json"
    }
  }

  if (localStorage.getItem('authToken')) {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem('authToken')}`
  }

  try {
    let response = await axios.delete(BASE_URL + url, config)
    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    blockCatch(error)
  }
}

export async function putAsync(url, data) {

  const config = {
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json"
    }
  }

  if (localStorage.getItem('authToken')) {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem('authToken')}`
  }

  try {
    let response = await axios.put(BASE_URL + url, data, config)

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      return response.data
    }

  } catch (error) {
    blockCatch(error)
  }

  return undefined
}