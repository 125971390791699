<template>
  <Carousel
      :items-to-show="1"
      :wrap-around="true"
      :autoplay="5000"
  >
    <Slide
        v-for="slide in mainInformation"
        :key="slide"
    >
      <div class="carousel__item" v-html="slide"></div>
    </Slide>

    <template #addons>
      <Navigation />
      <Pagination />
    </template>
  </Carousel>
</template>

<script>
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
export default {
  props: {
    mainInformation: Array
  },
  components: {
    Carousel,
    Navigation,
    Pagination,
    Slide,
  }
}
</script>

<style lang="scss">
@import "infoCarousel";
</style>