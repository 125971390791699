<template>
  <div class="navigation-mobile">
    <div class="navigation-mobile__list">
      <RouterLink to="/dashboard/fortune-wheel"
        class="navigation-mobile__item"
        :class="{ 'navigation-mobile__item--active': activeTab === 'fortune' }"
        @click="setActiveTab('fortune')"
      >
        <img src="@/assets/svg/furtune_icon.svg" alt="">
        <span>Fortune</span>
        <div
          class="navigation-mobile__item-blur"
          :style="{ opacity: activeTab === 'fortune' ? 1 : 0 }"
        />
      </RouterLink>

      <RouterLink to="/dashboard/history-participation"
        class="navigation-mobile__item"
        :class="{ 'navigation-mobile__item--active': activeTab === 'tickets' }"
        @click="setActiveTab('tickets')"
      >
        <img src="@/assets/svg/ticket_icon.png" alt="">
        <span>Tickets</span>
        <div
          class="navigation-mobile__item-blur"
          :style="{ opacity: activeTab === 'tickets' ? 1 : 0 }"
        />
      </RouterLink>

      <RouterLink to="/dashboard/available-lotteries"
        class="navigation-mobile__item"
        :class="{ 'navigation-mobile__item--active': activeTab === 'loto' }"
        @click="setActiveTab('loto')"
      >
        <img src="@/assets/svg/loto_icon.png" alt="">
        <span>Loto</span>
        <div class="navigation-mobile__item-circle"></div>
        <div
          class="navigation-mobile__item-blur"
          :style="{ opacity: activeTab === 'loto' ? 1 : 0 }"
        />
      </RouterLink>

      <RouterLink to="/dashboard/operations-history"
        class="navigation-mobile__item"
        :class="{ 'navigation-mobile__item--active': activeTab === 'history' }"
        @click="setActiveTab('history')"
      >
        <img src="@/assets/svg/history_icon.svg" alt="">
        <span>History</span>
        <div
          class="navigation-mobile__item-blur"
          :style="{ opacity: activeTab === 'history' ? 1 : 0 }"
        />
      </RouterLink>

      <RouterLink to="/dashboard/referral-program"
        class="navigation-mobile__item"
        :class="{ 'navigation-mobile__item--active': activeTab === 'partners' }"
        @click="setActiveTab('partners')"
      >
        <img src="@/assets/svg/partners_icon.png" alt="">
        <span>Partners</span>
        <div
          class="navigation-mobile__item-blur"
          :style="{ opacity: activeTab === 'partners' ? 1 : 0 }"
        />
      </RouterLink>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: 'fortune',
    }
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab
    }
  },
  mounted() {
    switch (this.$route.path) {
      case "/dashboard/fortune-wheel":
        this.activeTab = 'fortune'
        break
      case "/dashboard/history-participation":
        this.activeTab = 'tickets'
        break
      case "/dashboard/available-lotteries":
        this.activeTab = 'loto'
        break
      case "/dashboard/operations-history":
        this.activeTab = 'history'
        break
      case "/dashboard/referral-program":
        this.activeTab = 'partners'
        break
    }
  },
}
</script>

<style lang="scss" scoped>
@import "navigationMobile";
</style>