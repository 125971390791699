import * as API from '../../api/index'

export default {
  namespaced: true,
  state() {
    return {
      games: [],
      game: {},
    }
  },
  actions: {
    getGames({ state }) {
      API.getGames().then(response => {
        state.games = response
      } )
    },
    getGame({ state }, id) {
      API.getGame(id).then(response => {
        state.game = response
      })
    }
  },
  mutations: {

  },
  getters: {

  },
}
