import * as API from '../../api/index'
import router from "@/router";

export default {
  namespaced: true,
  state() {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      needed2fa: null,
      isAuth: !!localStorage.getItem('authToken')
    }
  },
  actions: {
    registration({ state, commit }, data) {
      API.registration(data).then(response => {
        const token = response?.token
        const user = response?.user

        if (token) {
          localStorage.setItem('authToken', token)
          document.cookie = "token=" + token
          commit('SET_AUTH', true)
          router.push('/dashboard/available-lotteries')
        }

        if (user) {
          commit('SET_USER', user)
        }
      })
    },
    login({ state, commit }, data) {
      API.login(data).then(response => {
        const token = response?.token
        const user = response?.user
        const needed2fa = response?.is_2fa_request_needed

        state.needed2fa = needed2fa

        if (token) {
          if (user == null) {
            localStorage.setItem('token2fa', token)
          } else {
            localStorage.setItem('authToken', token)
            document.cookie = "token=" + token
            commit('SET_AUTH', true)

            router.push('/dashboard/available-lotteries')
          }
        }

        if (user) {
          commit('SET_USER', user)
        }
      })
    },
    login2FA({ state, commit }, data) {
      API.login2FA(data).then(response => {
        const token = response?.token
        const user = response?.user

        if (token) {
          localStorage.setItem('authToken', token)
          document.cookie = "token=" + token
          commit('SET_AUTH', true)
          if (user != null) {
            router.push('/dashboard/available-lotteries')
          }
        }

        if (user) {
          commit('SET_USER', user)
        }
      })
    },
    sendResetEmail({ state }, data) {
      API.sendResetEmail(data).then(response => {
        if (response) {
          router.push('/auth/confirm-code')
        }
      })
    },
    oauthCallback({ state, commit }, config) {
      API.oauthCallback(config.provider, config.data).then(response => {
        const token = response?.token
        const user = response?.user
        const needed2fa = response?.is_2fa_request_needed

        state.needed2fa = needed2fa

        if (token) {
          if (user == null) {
            localStorage.setItem('token2fa', token)
          } else {
            localStorage.setItem('authToken', token)
            document.cookie = "token=" + token
            commit('SET_AUTH', true)

            router.push('/dashboard/available-lotteries')
          }
        }

        if (user) {
          commit('SET_USER', user)
        }
      })
    },
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
      localStorage.setItem('user', JSON.stringify(user))
    },
    SET_AUTH(state, boolean) {
      state.isAuth = boolean
    }
  },
  getters: {
    isAuthenticated(state) {
      return state.isAuth
    }
  },
}
