<template>
  <div class="password-reset">
    <h2 class="password-reset__title">{{getTranslation('Сброс пароля')}}</h2>
    <div class="password-reset__container">
      <div class="password-reset__block">
        <label for="text">{{getTranslation('Электронная почта или логин')}}</label>
        <Input
            :type="'email'"
            :id="'email'"
            :placeholder="getTranslation('Пожалуйста, введите электронную почту')"
            :auth="'auth'"
            @enter-input-modal="inputEmail"
        />
      </div>
      <Button @click="sendEmail">
        {{getTranslation('Далее')}}
      </Button>
      <div class="password-reset__under">
        <span>{{getTranslation('Я не помню электронную почту')}}</span>
        <router-link to="#">{{getTranslation('Ввести логин')}}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/UI/Input/Input.vue";
import Button from "@/components/UI/Button/Button.vue";
import {mapGetters} from "vuex";

export default {
  data () {
    return {
      email: ''
    }
  },
  methods: {
    inputEmail (inputData) {
      this.email = inputData
      console.log(this.email)
    },
    sendEmail () {
      this.$store.dispatch('auth/sendResetEmail', { email: this.email})
    }
  },
  components: {
    Button,
    Input,
  },
  computed:{
    ...mapGetters('locale', ['getTranslation'])
  }
}
</script>

<style lang="scss" scoped>
@import "passwordReset";
</style>