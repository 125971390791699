<template>
  <RouterView />
</template>

<script setup>

</script>

<style lang="scss">

</style>
