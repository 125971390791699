<template>
  <div class="modal-2fa">
    <button @click="closeModal" class="modal-2fa__exit-btn">
      <img src="@/assets/svg/exit-icon.svg" alt="exit">
    </button>
    <div class="modal-2fa__container">
      <div class="modal-2fa__head">
        <img src="@/assets/image/shield-auth.png" alt="">
        <h2>{{getTranslation('2FA Google аутентификация')}}</h2>
      </div>
      <div class="modal-2fa__instructions">
        <div class="modal-2fa__instructions-block">
          <div class="modal-2fa__instructions-number">1</div>
          <div class="modal-2fa__instructions-text">
            <span>{{getTranslation('Установите приложение Google 2FA на свой телефон. Скачать приложение можно по')}} </span>
            <router-link to="#">{{getTranslation('ссылке')}} </router-link>
          </div>
        </div>
        <div class="modal-2fa__instructions-block">
          <div class="modal-2fa__line">
            <div></div>
          </div>
        </div>
        <div class="modal-2fa__instructions-block">
          <div class="modal-2fa__instructions-number">2</div>
          <div class="modal-2fa__instructions-text">
            <span>{{getTranslation('Откройте приложение Google 2FA и отсканируйте данный QR код')}}</span>
          </div>
        </div>
        <div class="modal-2fa__instructions-block">
          <div class="modal-2fa__line modal-2fa__line--big">
            <div class=""></div>
          </div>
          <div class="modal-2fa__instructions-content">
            <div v-if="secretKey?.google2fa_qr" class="modal-2fa__instructions-qr">
              <qrcode :value="secretKey?.google2fa_qr"></qrcode>
            </div>
            <span>{{getTranslation('Если у вас не получится отсканировать данный QR код сохраните ниже приведенный код')}}</span>
            <div class="modal-2fa__instructions-code">
              <span>{{ secretKey?.google2fa_secret }}</span>
              <div>
                <button @click="copySecretKey">
                  <img src="@/assets/svg/ion_copy.svg" alt="">
                </button>
                <button @click="rebootSecretKey">
                  <img src="@/assets/svg/restart-gray.svg" alt="">
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-2fa__instructions-block">
          <div class="modal-2fa__instructions-number">3</div>
          <div class="modal-2fa__instructions-text">
            <span>{{getTranslation('Введите 6-значный код, который сгенерировался в приложении')}}</span>
          </div>
        </div>
      </div>
      <Input
          :placeholder="getTranslation('Пожалуйста, введите 6-значный код')"
          type="number"
          @enter-input-modal="enterCode"
      />
      <Button @click="enable2FA">
        {{getTranslation('Подключить аутентификатор')}}
      </Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/UI/Button/Button.vue";
import Input from "@/components/UI/Input/Input.vue";
import {mapState,mapGetters} from "vuex";
import Qrcode from 'vue-qrcode';

export default {
  data () {
    return {
      inputCode: '',
    }
  },
  methods: {
    enterCode (inputModal) {
      this.inputCode = inputModal
    },
    enable2FA () {
      this.$store.dispatch('settings/enable2FA', { data: { one_time_password: this.inputCode.toString() }, component: this })
    },
    closeModal() {
      this.$emit('close-modal')
    },
    copySecretKey() {
      const textToCopy = this.secretKey?.google2fa_secret
      const textarea = document.createElement('textarea')

      textarea.value = textToCopy
      document.body.appendChild(textarea)
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)

      this.copyAlert = true

      setTimeout(() => {
        this.copyAlert = false
      }, 2000)

      this.$store.commit('notifications/SUCCESS_CHANGE', 'Код успешно скопирован')
    },
    rebootSecretKey() {
      this.$store.dispatch('settings/secretKey2FA')
    }
  },
  mounted () {
    this.$store.dispatch('settings/secretKey2FA')
  },
  computed: {
    ...mapState('settings', ['secretKey']),
    ...mapGetters('locale', ['getTranslation'])
  },
  components: {
    Input,
    Button,
    Qrcode
  },
}
</script>

<style lang="scss" scoped>
@import "modal2FA";
</style>