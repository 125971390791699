<template>
  <div class="modal">
    <div class="modal__container">
      <div class="modal__content">
        <div class="modal-prize">
          <div v-if="false" class="modal-prize__ticket">
            <img class="modal-prize__bg" src="@/assets/image/prize_bg.png" alt="">
            <img class="modal-prize__border" src="@/assets/image/prize_border.png" alt="">
            <span class="modal-prize__number">№32434332</span>
          </div>
          <div class="modal-prize__usdt">
            <img src="@/assets/svg/USDT-icon.svg" alt="">
          </div>
          <div class="modal-prize__count">
            +{{ prize }}
            <div class="modal-prize__blur" />
          </div>
        </div>
        <img class="modal-prize__corner modal-prize__corner--one" src="@/assets/svg/corner-how-work.svg" alt="">
        <img class="modal-prize__corner modal-prize__corner--two" src="@/assets/svg/corner-how-work.svg" alt="">
      </div>
    </div>
    <div @click="closeModal" class="modal__overlay"></div>
  </div>
</template>

<script>
export default {
  props: {
    prize: String
  },
  methods: {
    closeModal() {
      this.$emit('close-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "modalPrize";
</style>