<template>
  <div class="history-participation">
    <div class="_container">
      <div class="history-participation__head">
        <h2 class="history-participation__title">{{getTranslation('История участия')}}</h2>
        <HistoryTabs
            @open-history="openHistory"
        />
      </div>

     <!--  Активные -->
      <div v-if="isHistory === 1">
        <EmptyBlock v-if="historyActive?.length === 0">
          {{getTranslation('Нет активных лотерей')}}
        </EmptyBlock>
        <div v-else class="history-participation__container">
          <AvailableCard
              v-for="room in historyActive"
              :room="room"
              @click="openActive(room)"
          />
        </div>
      </div>

    <!--   Завершённые   -->
      <div v-if="isHistory === 2">
        <EmptyBlock v-if="historyCompleted?.length === 0">
          {{getTranslation('Нет завершенных лотерей')}}
        </EmptyBlock>
        <div v-else class="history-participation__container">
          <CardCompleted
              v-for="room in historyCompleted"
              :room="room"
              @open-completed="openCompleted(room)"
          />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import HistoryTabs from "@/components/UI/HistoryTabs/HistoryTabs.vue";
import AvailableCard from "@/components/AvailableCard/AvailableCard.vue";
import CardCompleted from "@/components/CardCompleted/CardCompleted.vue";
import {mapGetters, mapState} from "vuex";
import EmptyBlock from "@/components/EmptyBlock/EmptyBlock.vue";

export default {
  data () {
    return {
      isHistory: 1
    }
  },
  methods: {
    openHistory(num) {
      this.isHistory = num
    },
    openActive(room) {
      if (this.isAuthenticated) {
        this.$router.push('/dashboard/lottery/' + room?.id)
      } else {
        this.$router.push('/auth/authorization')
      }
    },
    openCompleted(room) {
      if (this.isAuthenticated) {
        this.$router.push('/dashboard/completed-lottery/' + room?.id)
      } else {
        this.$router.push('/auth/authorization')
      }
    }
  },
  mounted() {
    this.$store.dispatch('rooms/getHistoryActive')
    this.$store.dispatch('rooms/getHistoryCompleted')
  },
  computed: {
    ...mapState('rooms', ['historyActive']),
    ...mapState('rooms', ['historyCompleted']),
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters('locale', ['getTranslation'])
  },
  beforeRouteLeave(to, from, next) {
    to.query.previousPath = from.path;
    next();
  },
  components: {
    EmptyBlock,
    CardCompleted,
    AvailableCard,
    HistoryTabs
  }
}
</script>

<style lang="scss" scoped>
@import "historyParticipation";
</style>