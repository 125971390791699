import * as API from '../../api/index'

export default {
  namespaced: true,
  state() {
    return {
      rooms: [],
      room: {},
      activeRooms: [],
      lastRoomsWinners: [],
      historyActive: [],
      historyCompleted: [],
    }
  },
  actions: {
    getRooms({ state }) {
      API.getRooms().then(response => {
        state.rooms = response
        console.log(response)
      })
    },
    getRoom({ state }, id) {
      API.getRoom(id).then(response => {
        if (response) {
          state.room = response
        }
      })
    },
    getActiveRoom({ state }) {
      API.getActiveRooms().then(response => {
        state.activeRooms = response
      })
    },
    getLastWinners({ state }, data) {
      API.getLastWinners(data).then(response => {
        state.lastRoomsWinners = response
      })
    },
    getHistoryActive({ state }) {
      API.getHistoryActive().then(response => {
        state.historyActive = response
      })
    },
    getHistoryCompleted({ state }) {
      API.getHistoryCompleted().then(response => {
        state.historyCompleted = response
      })
    }
  },
  mutations: {

  },
  getters: {

  },
}
