<template>
  <div ref="rwSelect" class="rw-select">
    <div
      @click="toggleOpen"
      :class="{ 'open': openSelected }"
      class="rw-select__selected"
    >
      <div class="rw-select__inner">
        <span v-if="!network" class="rw-select__inner-name">{{ reactiveSelected.value }}</span>
        <span v-else class="rw-select__inner-name">{{ selectedNetwork }}</span>
      </div>
      <img src="@/assets/svg/arrow-right-gray.svg" alt="">
    </div>
    <div v-if="openSelected" class="rw-select__list">
      <div
        v-for="(item, idx) in data"
        :key="idx"
        @click="changeSelected(item, idx)"
        class="rw-select__item"
      >
        <span class="rw-select__item-name">{{ item?.name || item }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue';
import { mapState } from 'vuex';

export default {
  props: {
    data: {
      type: Array,
      default: [],
    },
    selected: {
      type: String,
    },
    network: {
      type: Boolean,
    }
  },
  data() {
    return {
      openSelected: false,
      reactiveSelected: reactive({
        value: this.selected,
      })
    }
  },
  computed: {
    ...mapState('payment', ['selectedNetwork'])
  },
  methods: {
    toggleOpen() {
      this.openSelected = !this.openSelected
      if (this.openSelected) {
        document.addEventListener('click', this.handleClickOutside)
      } else {
        document.removeEventListener('click', this.handleClickOutside)
      }
    },
    changeSelected(item, idx) {
      this.reactiveSelected.value = item?.name || item
      this.openSelected = false
      this.$emit('selected-item', item, idx)
      if (this.network) {
        this.$store.commit('payment/CHANGE_SELECTED_NETWORK', item)
      }
      document.removeEventListener('click', this.handleClickOutside)
    },
    handleClickOutside(event) {
      if (this.$refs.rwSelect && !this.$refs.rwSelect.contains(event.target)) {
        this.openSelected = false
        document.removeEventListener('click', this.handleClickOutside)
      }
    }
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  }
}
</script>

<style lang="scss" scoped>
@import "rWSelect";
</style>