<template>
  <div class="auth">
    <MainHeader
        @open-about-company="openAboutCompany= true"
        @toggle-burger="toggleBurger"
    />
    <main class="auth__wrapper">
      <div class="auth__container">
        <div class="auth__container-image">
          <img src="@/assets/image/coins-auth.png" alt="">
        </div>
        <RouterView
            @enter-email="enterEmail"
            @enter-name="enterName"
            @enter-password="enterPassword"
            @register="register"
            @open-modal-2fa="openModal(4)"
            @set-referral-cod="setReferralCod"
        />
      </div>
    </main>
    <img class="auth__bg" src="@/assets/image/bg-auth.png" alt="">
    <Error />
    <Success />
    <Modal
        @close-modal="closeModal(4)"
        :toggleModal="toggleModal"
        :openModals="openModals"
    />
    <MainBurger
        :is-burger="isBurger"
        @close-burger="isBurger = false"
        @open-about-company="openAboutCompany = true"
    />
    <ModalAboutCompany
        v-if="openAboutCompany"
        @close-modal="openAboutCompany = false"
    />
  </div>
</template>

<script>
import MainHeader from "@/components/layouts/Main/MainHeader/MainHeader.vue";
import Error from "@/components/Notification/Error/Error.vue";
import Success from "@/components/Notification/Success/Success.vue";
import Modal from "@/components/Modals/Modal.vue";
import {mapState} from "vuex";
import MainBurger from "@/components/layouts/Main/MainBurger/MainBurger.vue";
import ModalAboutCompany from "@/components/Modals/ModalAboutCompany/ModalAboutCompany.vue";

export default {
  data() {
    return {
      dataRegistration: {
        username: '',
        email: '',
        password: '',
        referral_code: ''
      },
      toggleModal: false,
      openModals: 0,
      isBurger: false,
      openAboutCompany: false
    }
  },
  methods: {
    enterEmail(inputData) {
      this.dataRegistration.email = inputData
    },
    enterName(inputData) {
      this.dataRegistration.username = inputData
    },
    enterPassword(inputData) {
      this.dataRegistration.password = inputData
    },
    setReferralCod(code) {
      this.dataRegistration.referral_code = code
      console.log(this.dataRegistration)
    },
    register() {
      this.$store.dispatch('auth/registration', this.dataRegistration)
    },
    openModal(num) {
      this.toggleModal = true
      this.openModals = num
    },
    closeModal(num) {
      this.toggleModal = false
      this.openModals = 0

      if (num === 4) {
        localStorage.removeItem('token2fa')
      }
    },
    toggleBurger() {
      this.isBurger = !this.isBurger
    }
  },

  components: {
    ModalAboutCompany,
    MainBurger,
    Modal,
    MainHeader,
    Error,
    Success,
  }
}
</script>

<style lang="scss" scoped>
@import "authenticationLayout";
</style>