<template>
  <div class="replenishment-choice">
    <div class="replenishment-choice__title">
      <div>1</div>
      <span>{{getTranslation('Выберите валюту для пополнения')}}</span>
    </div>
    <div class="replenishment-choice__data">
      <div class="replenishment-choice__dashed">
        <div></div>
      </div>
      <div class="replenishment-choice__select">
        <RWSelect
            :data="allTickers"
            :selected="getTranslation('Выберите валюту')"
            @selected-item="setSelectedCurrency"
        />
      </div>
    </div>
    <div class="replenishment-choice__title">
      <div>2</div>
      <span>{{getTranslation('Выберите сеть')}}</span>
    </div>
    <div class="replenishment-choice__data">
      <div class="replenishment-choice__dashed">
        <div></div>
      </div>
      <div class="replenishment-choice__select">
        <RWSelect
            :data="networks"
            :network="true"
            @selected-item="setSelectedNetwork"
        />
      </div>
    </div>
    <div class="replenishment-choice__title">
      <div>3</div>
      <span>{{getTranslation('Укажите сумму пополнения')}}</span>
    </div>
    <div class="replenishment-choice__data">
      <div class="replenishment-choice__dashed"></div>
      <div class="replenishment-choice__select">
        <Input
            :type="'number'"
            :placeholder="getTranslation('Укажите сумму пополнения')"
            @enter-input-modal="enterAmount"
            @focus-input="focusInput"
        />
      </div>
    </div>
    <div class="replenishment-choice__next">
      <span ref="alert-amount" class="replenishment-choice__alert-amount">{{getTranslation('Укажите сумму пополнения')}}</span>
      <Button @click="nextReplenishment">
        {{getTranslation('Далее')}}
      </Button>
    </div>
  </div>
</template>

<script>
import RWSelect from "@/components/UI/RWSelect/RWSelect.vue";
import Input from "@/components/UI/Input/Input.vue";
import Button from "@/components/UI/Button/Button.vue";
import {mapState,mapGetters} from "vuex";

export default {
  data() {
    return {
      dataReplenishment: {
        ticker: '',
        blockchain: '',
        amount: null,
      },
    }
  },
  methods: {
    enterAmount(input) {
      this.$emit('enter-input', input)
      this.dataReplenishment.amount = input
    },
    setSelectedCurrency(item, idx) {
      this.dataReplenishment.ticker = item.ticker

      this.$store.commit('payment/CHOOSE_NETWORK', idx)
      this.$store.commit('payment/CHANGE_SELECTED_NETWORK', this.getTranslation('Выберите сеть'))
    },
    setSelectedNetwork(item) {
      this.dataReplenishment.blockchain = item
    },
    nextReplenishment() {
      this.$store.dispatch('deposit/createDepositPayment', this.dataReplenishment)
      if (this.amount === '') {
        this.$refs["alert-amount"].style.display = 'block'
      } else {
        this.$emit('open-payment')
      }
      this.$store.commit('payment/CHANGE_SELECTED_NETWORK',this.getTranslation('Выберите сеть') )
    },
    focusInput() {
      this.$refs["alert-amount"].style.display = 'none'
    }
  },
  mounted() {
    this.$store.dispatch('payment/getAllAvailableTickers')
  },
  computed: {
    ...mapState('payment', ['allTickers', 'networks']),
    ...mapGetters('locale', ['getTranslation'])
  },
  components: {
    Button,
    Input,
    RWSelect,
  }
}
</script>

<style lang="scss" scoped>
@import "replenishmentChoice";
</style>