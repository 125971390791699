<template>
  <div class="faq-details">
    <div
        @click="openDetails = !openDetails"
        class="faq-details__summary">
      <span>{{ faq?.question }}</span>
      <img :class="{ 'open': openDetails }" src="../../../assets/svg/arrow-right-gray.svg" alt="arrow">
    </div>
    <div
        :class="{ 'open': openDetails }"
        class="faq-details__collapse"
    >
      <div style="min-height: 0">
        {{ faq?.answer }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    faq: Object
  },
  data() {
    return {
      openDetails: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "dashboardFAQDetails";
</style>