import * as API from '../../api/index'
import {getAboutCompany, getPrivacy, getTerms} from "../../api/index";

export default {
    namespaced: true,
    state() {
        return {
            aboutCompany: null,
            terms: null,
            privacy: null,
        }
    },
    actions: {
        getAboutCompany({ state }) {
            API.getAboutCompany().then(response => {
                state.aboutCompany = response
            })
        },
        getTerms({ state }) {
            API.getTerms().then(response => {
                state.terms = response
            })
        },
        getPrivacy({ state }) {
            API.getPrivacy().then(response => {
                state.privacy = response
            })
        }
    },
    mutations: {

    },
    getters: {

    },
}
