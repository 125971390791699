import payment from "@/store/modules/payments/payment";
import * as API from '../../../api'

export default {
  namespaced: true,
  state() {
    return {
      dataWithdrawal: [],
      withdrawalInfo: null,
    }
  },
  actions: {
    createWithdrawalPayment({ state, commit, dispatch }, data) {
      API.createWithdrawalPayment(data).then(response => {
        if(response) {
          dispatch('wallet/getWallet', null, { root: true })
          commit('notifications/PAYMENT_MODAL', {
            title: 'Заявка на вывод отправлена',
            text: 'Для проверки статуса операции перейдите на страницу'
          }, { root: true })
        }
      })
    },
    getDataWithdrawal({ state }) {
      API.getDataWithdrawal().then(response => {
        if(response) {
          state.dataWithdrawal = response
        }
      })
    },
    getWithdrawalInfo({ state }, data) {
      API.getWithdrawalInfo(data).then(response => {
        if(response) {
          state.withdrawalInfo = response
        }
      })
    }
  },
  mutations: {

  },
  getters: {

  },
}
