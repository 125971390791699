<template>
  <div class="empty">
    <img src="@/assets/image/wave-empty-container.png" alt="">
    <span>
      <slot />
    </span>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import "emptyBlock";
</style>