<template>
  <div class="ticket" :class="ticket?.status">
    <span>№{{ ticket?.id }}</span>
    <svg width="144" height="41" viewBox="0 0 144 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_15_2671)">
        <path d="M138.321 0C138.766 0 139.193 0.193154 139.508 0.536971C139.823 0.880787 140 1.3471 140 1.83333V11.9167C138.887 11.9167 137.819 12.3996 137.032 13.2591C136.245 14.1186 135.802 15.2844 135.802 16.5C135.802 17.7156 136.245 18.8814 137.032 19.7409C137.819 20.6004 138.887 21.0833 140 21.0833V31.1667C140 31.6529 139.823 32.1192 139.508 32.463C139.193 32.8068 138.766 33 138.321 33H5.67901C5.23371 33 4.80665 32.8068 4.49177 32.463C4.1769 32.1192 4 31.6529 4 31.1667V21.0833C5.11325 21.0833 6.18091 20.6004 6.9681 19.7409C7.75529 18.8814 8.19753 17.7156 8.19753 16.5C8.19753 15.2844 7.75529 14.1186 6.9681 13.2591C6.18091 12.3996 5.11325 11.9167 4 11.9167V1.83333C4 1.3471 4.1769 0.880787 4.49177 0.536971C4.80665 0.193154 5.23371 0 5.67901 0L138.321 0Z" fill="#3D8D7F"/>
      </g>
      <defs>
        <filter id="filter0_d_15_2671" x="0" y="0" width="144" height="41" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="2"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_2671"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_2671" result="shape"/>
        </filter>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    ticket: {}
  },
}
</script>

<style lang="scss" scoped>
@import "lotteryTicket";
</style>