<template>
  <div @click.stop="" class="counter-button">
    <button
        @click.stop="minusCount"
    >
      -
    </button>
    <span>{{ count }}</span>
    <button
        @click.stop="plusCount"
    >
      +
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      count: 1,
    }
  },
  methods: {
    plusCount() {
      this.count++
      this.$emit('change-count', this.count)
    },
    minusCount() {
      if (this.count > 1) {
        this.count = this.count - 1
      }
      this.$emit('change-count', this.count)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "counterButton";
</style>