<template>
  <div class="possibilities-block">
    <div class="possibilities-block__circle">
      <span>{{ possibility.id + 1 }}</span>
      <img class="possibilities-block__dots" src="../../assets/image/dots.png" alt="">
      <div class="possibilities-block__blur"></div>
    </div>
    <div class="possibilities-block__info">
      <h3 class="possibilities-block__title">{{ possibility.title }}</h3>
      <span class="possibilities-block__text">{{ possibility.subtitle }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    possibility: Object
  }
}
</script>

<style lang="scss" scoped>
@import "possibilitiesBlock";
</style>