<template>
  <div v-if="toggleModal || successModal" class="modal">
    <div class="modal__container">
      <div class="modal__content">
        <ModalChangePassword
            v-if="openModals === 1"
            @close-modal="$emit('close-modal')"
            @open-banner-password="$emit('open-banner-password')"
        />
        <ModalChangeEmail
            v-if="openModals === 2"
            @close-modal="$emit('close-modal')"
            @open-banner-email="$emit('open-banner-email')"
        />
        <Modal2FA
            v-if="openModals === 3"
            @close-modal="$emit('close-modal')"
            @open-banner-2fa="$emit('open-banner-2fa')"
        />
        <ModalLogin2Fa
            v-if="openModals === 4"
            @close-modal="$emit('close-modal')"
        />
        <ModalAcceptBuy
            v-if="successModal"
            :success-modal="successModal"
            @close-modal="closeModal"
        />
      </div>
    </div>
    <div @click="closeModal" class="modal__overlay"></div>
  </div>
</template>

<script>
import Modal2FA from "@/components/Modals/Modal2FA/Modal2FA.vue";
import ModalLogin2Fa from "@/components/Modals/ModalLogin2FA/ModalLogin2Fa.vue";
import ModalChangePassword from "@/components/Modals/ModalChangePassword/ModalChangePassword.vue";
import ModalChangeEmail from "@/components/Modals/ModalChangeEmail/ModalChangeEmail.vue";
import ModalAcceptBuy from "@/components/Modals/ModalAcceptBuy/ModalAcceptBuy.vue";
import {mapState} from "vuex";

export default {
  props: {
    toggleModal: {
      type: Boolean,
      default: false,
    },
    openModals: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    closeModal () {
      this.$emit('close-modal')
      this.$store.commit('notifications/REMOVE_MODAL')
    }
  },
  computed: {
    ...mapState('notifications', ['successModal']),
  },
  components: {
    ModalChangeEmail,
    ModalChangePassword,
    Modal2FA,
    ModalLogin2Fa,
    ModalAcceptBuy
  }
}
</script>

<style lang="scss" scoped>
@import "modal";
</style>