<template>
  <div class="notification">
    <div
        :class="{'animation__notify': error}"
        class="notify-422"
    >
      <img src="../../../assets/svg/warning.png" alt="">
      <span>{{ error }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data () {
    return {

    }
  },
  computed: {
    ...mapState('notifications', ['error'])
  }
}
</script>

<style lang="scss" scoped>
@import "error";
</style>