<template>
  <div class="winner-block">
    <div class="winner-block__info">
      <span class="winner-block__username">{{ winner.user?.username }}</span>
      <span class="winner-block__reward">+{{ winner?.prize }} USDT</span>
    </div>
    <div class="winner-block__avatar">
      <img :src="winner.user?.avatar" alt="">
    </div>
    <img class="corner__right" src="../../assets/svg/corner-winners.svg" alt="">
    <img class="corner__left" src="../../assets/svg/corner-winners.svg" alt="">
  </div>
</template>

<script>
export default {
  props: {
    winner: {
      type: Object,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "winnerBlock";
</style>
