<template>
  <div class="notification">
    <div
        :class="{'animation__notify': success}"
        class="notification__block"
    >
      <img src="@/assets/svg/accept-mark.svg" alt="">
      <span>{{ success }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data () {
    return {

    }
  },
  computed: {
    ...mapState('notifications', ['success'])
  }
}
</script>

<style lang="scss" scoped>
@import "success"
</style>