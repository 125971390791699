import * as API from "../../api/index";

export default {
  namespaced: true,
  state() {
    const savedLocale = localStorage.getItem("locale")
    return {
      locale: savedLocale && savedLocale !== "undefined" ? JSON.parse(savedLocale) : [],
      currentLocale: localStorage.getItem("currentLocale"),
    };
  },
  actions: {
    getlocales({ state, commit }) {
      API.getLocales().then((response) => {
        if(response == undefined) return;
        
        state.locale = response;
        localStorage.setItem("locale", JSON.stringify(state.locale));
        if (state.currentLocale == null) {
          commit("SET_CURRENT_LOCALE", state.locale[0].title);
        }
      });
    },
    setCurrentLocale({ state, commit }, locale) {
      commit("SET_CURRENT_LOCALE", locale);
    },
  },
  mutations: {
    SET_CURRENT_LOCALE(state, locale) {
      state.currentLocale = locale;
      localStorage.setItem("currentLocale", locale);
    },
  },
  getters: {
    getTranslation: (state) => (title) => {
      let locale = state.locale?.find(
        (locale_data) => locale_data.title === state.currentLocale
      );
      let translation = locale?.translations.find((el) => el.title === title);
      return translation?.pivot?.value ?? title;
    },
    getSelectedLanguageIcon: (state) => () => {
      let locale = state.locale?.find(
        (locale_data) => locale_data.title === state.currentLocale
      );
      return locale?.image || '';
    },
  },
};
