<template>
  <div class="lottery">
    <div class="_container">
      <div class="lottery__wrapper">
        <div class="lottery__header">
          <div class="lottery__head">
            <ButtonBack @click="backRoute" />
            <div>
              <h2 class="lottery__title">{{ room.game?.title }}</h2>
            </div>
          </div>
          <div class="lottery__transaction">
            <span>{{getTranslation('Смарт контракт лотереи')}}</span>
            <a :href="room.game?.web3_contract" target="_blank">
              {{ room.game?.web3_contract }}
            </a>
          </div>
        </div>
        <div class="lottery__container">
          <div>
            <LotteryCardCompleted
                :room="room"
            />
            <div class="lottery__transaction">
              <span>{{getTranslation('Tранзакция с результатом проведенной лотереи')}}</span>
              <a :href="room?.web3_transaction" target="_blank">
                {{ room?.web3_transaction }}
              </a>
            </div>
          </div>
          <div class="lottery__block">
            <div class="lottery__block-head">
              <h3 class="lottery__block-title">{{getTranslation('Билеты лотереи')}}</h3>
              <div>
                <span>{{ room.participants?.length }} {{ participantsCount }}</span>
                {{getTranslation('учавствовали в лотерее')}}
              </div>
            </div>
            <div v-if="room?.participants" class="lottery__member-list">
              <LotteryMember
                  v-for="participant in room?.participants"
                  :participant="participant"
              />
            </div>
            <div v-if="room.participants?.length === 0" class="lottery__member-empty">
              <img src="@/assets/image/wave-empty-container.png" alt="">
              <span>{{getTranslation('Еще никто не учавствует')}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AvailableCard from "@/components/AvailableCard/AvailableCard.vue";
import LotteryMember from "@/components/LotteryMember/LotteryMember.vue";
import ButtonBack from "@/components/UI/ButtonBack/ButtonBack.vue";
import LotteryCardCompleted from "@/components/LotteryCardCompleted/LotteryCardCompleted.vue";
import {mapState, mapGetters} from "vuex";

export default {
  data() {
    return {
      path: ''
    }
  },
  methods: {
    backRoute () {
      if (this.path) {
        this.$router.push(this?.path)
      } else {
        this.$router.push('/dashboard/available-lotteries')
      }
    },
    redirectToContract() {
      window.location.href = this.room.game?.web3_contract
    },
    redirectToTransaction() {
      window.location.href = this.room?.web3_transaction
    }
  },
  mounted() {
    this.$store.dispatch('rooms/getRoom', +this.$route.params.id)
  },
  computed: {
    ...mapGetters('locale', ['getTranslation']),
    ...mapState('rooms', ['room']),
    participantsCount () {
      let tickets = this.room.participants?.length
      if (tickets === 1) {
        return 'пользователь'
      } else if (tickets >=2 && tickets <= 4) {
        return 'пользователя'
      } else {
        return 'пользователей'
      }
    }
  },
  components: {
    LotteryCardCompleted,
    ButtonBack,
    LotteryMember,
    AvailableCard,
  },
  created() {
    this.path = this.$route.query.previousPath;
  }
}
</script>

<style lang="scss" scoped>
@import "completedLottery";
</style>