<template>
  <div class="history-tabs">
    <div
        @click="openTab(1)"
        class="history-tabs__tab"
        :class="{ 'open':  tab === 1 }"
    >
      {{getTranslation('Активные')}}
    </div>
    <div
        @click="openTab(2)"
        class="history-tabs__tab"
        :class="{ 'open':  tab === 2 }"
    >
      {{getTranslation('Завершённые')}}
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  data() {
    return {
      tab: 1,
    }
  },
  computed:{
    ...mapGetters('locale', ['getTranslation'])
  },
  methods: {
    openTab(num) {
      this.tab = num
      this.$emit('open-history', num)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "historyTabs";
</style>