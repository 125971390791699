<template>
  <div class="modal-change-password">
    <button @click="closeModal" class="modal-change-password__exit-btn">
      <img src="@/assets/svg/exit-icon.svg" alt="exit">
    </button>
    <div class="modal-change-password__container">
      <div class="modal-change-password__head">
        <img src="@/assets/image/lock-password.png" alt="">
        <h2>{{getTranslation('Смена пароля')}}</h2>
      </div>
      <Input
          :placeholder="getTranslation('Введите новый пароль')"
          type="password"
          @enter-input-modal="enterPassword"
      />
      <Input
          :placeholder="getTranslation('Повторите пароль')"
          type="password"
          @enter-input-modal="enterRepeatPassword"
      />
      <Button @click="changePassword">
        {{getTranslation('Изменить пароль')}}
      </Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/UI/Button/Button.vue";
import Input from "@/components/UI/Input/Input.vue";
import {mapGetters} from "vuex";

export default {
  data () {
    return {
      password: '',
      repeatPassword: '',

    }
  },
  methods: {
    enterPassword (inputData) {
      this.password = inputData
    },
    enterRepeatPassword (inputData) {
      this.repeatPassword = inputData
    },
    changePassword() {
      if (this.password === this.repeatPassword) {
        this.$store.dispatch('settings/changePassword', { password: { password: this.password }, component: this })
      } else {
        this.$store.commit('notifications/ERROR_CHANGE', 'Пароли не совпадают')
      }
    },
    closeModal() {
      this.$emit('close-modal')
    }
  },
  computed: {
    ...mapGetters('locale', ['getTranslation'])
  },
  components: {
    Input,
    Button
  },
}
</script>

<style lang="scss" scoped>
@import "modalChangePassword";
</style>