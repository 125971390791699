<template>
  <div class="all-ticket-user">
    {{getTranslation('Всего')}} {{ ticketsText.payed }}
    <span>{{ tickets }} {{ ticketsText.ticket }}</span>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  props: {
    tickets: {}
  },
  computed: {
    ...mapGetters('locale', ['getTranslation']),
    ticketsText() {
      let count = this.tickets

      if (count % 100 >= 5 && count % 100 <= 20) {
        return { ticket: this.getTranslation('билетов') , payed:  this.getTranslation('куплено') };
      }
      switch (count % 10) {
        case 1: return { ticket: this.getTranslation('билет'), payed:  this.getTranslation('куплен') };
        case 2:
        case 3:
        case 4: return { ticket: this.getTranslation('билета') , payed:  this.getTranslation('куплено') };
        default: return { ticket:   this.getTranslation('билетов') , payed:  this.getTranslation('куплено') };
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "allTicketsUser";
</style>