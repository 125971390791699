<template>
  <main>
    <div class="_container">
      <div class="cards-games">
        <MainCardGame
            v-for="game in games"
            :game="game"
            @click="openGame(game)"
        />
      </div>
      <div class="winners">
        <div class="winners__title">
          <div class="winners__status">
            <span>Live</span>
            <div class="winners__dot"></div>
            <span>{{getTranslation('Победители')}}</span>
          </div>
          <span class="winners__last">{{getTranslation('Последние выигрыши')}}</span>
        </div>
        <div class="winners__overflow">
          <div class="winners__container">
            <WinnerBlock
                v-for="winner in lastRoomsWinners"
                :winner="winner"
            />
          </div>
        </div>
      </div>
      <div class="possibilities">
        <div class="possibilities__relative">
          <h2 class="possibilities__title">{{getTranslation('Возможности платформы')}}</h2>
          <img src="@/assets/image/wave-possibilities.png" alt="">
          <div class="possibilities__container">
            <PossibilitiesBlock
                v-for="possibility in possibilities"
                :key="possibility.id"
                :possibility="possibility"
            />
          </div>
          <Button
              @click="registration"
          >
            {{getTranslation('Начать играть')}}
          </Button>
        </div>
        <img class="possibilities__rectangle" src="@/assets/image/possibilities-rectangle.png" alt="">
      </div>
      <div class="info-carousel">
        <InfoCarousel :mainInformation="mainInformation"/>
      </div>
      <div class="platform-bg">
        <img class="platform-bg__image" src="@/assets/image/main-platform-img.png" alt="">
        <img class="platform-bg__coin" src="@/assets/image/platform-coin.png" alt="">
        <div class="platform-bg__blur"></div>
        <img class="platform-bg__coin-left" src="@/assets/image/platform-coin-left.png" alt="">
        <img class="platform-bg__coin-right" src="@/assets/image/platform-coin-right.png" alt="">
      </div>
    </div>
    <div class="faq">
      <h2 class="faq__title">{{getTranslation('FAQ')}}</h2>
      <div class="faq__container">
        <FaqDetails
            v-for="faq in faqList"
            :key="faq.id"
            :faq="faq"
        />
      </div>
    </div>
  </main>

</template>

<script>
import MainCardGame from "@/components/MainCardGame/MainCardGame.vue";
import WinnerBlock from "@/components/WinnerBlock/WinnerBlock.vue";
import Button from "@/components/UI/Button/Button.vue";
import PossibilitiesBlock from "@/components/PossibilitiesBlock/PossibilitiesBlock.vue";
import InfoCarousel from "@/components/UI/InfoCarousel/InfoCarousel.vue";
import FaqDetails from "@/components/UI/FaqDetails/FaqDetails.vue";
import {mapGetters, mapState} from "vuex";

export default {
  data() {
    return {
      count: 5,

    }
  },
  methods: {
    openGame(game) {
      if (this.isAuthenticated) {
        this.$router.push('/dashboard/lottery/' + game.id)
      } else {
        this.$router.push('/auth/authorization')
      }
    },
    registration() {
      if (this.isAuthenticated) {
        this.$router.push('/dashboard/available-lotteries')
      } else {
        this.$router.push('/auth/registration')
      }
    }
  },
  mounted() {
    this.$store.dispatch('games/getGames')
    this.$store.dispatch('rooms/getLastWinners', { count: this.count })
    this.$store.dispatch('faq/faqList')
  },
  computed: {
    ...mapState('games', ['games']),
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapState('rooms', ['lastRoomsWinners']),
    ...mapState('faq', ['faqList']),
    ...mapGetters('locale', ['getTranslation']),
    possibilities() {
      return [
        {
          id: 0,
          title: this.getTranslation('Прозрачность'),
          subtitle: this.getTranslation('Каждый игрок может проверить результаты розыгрыша непосредственно в блокчейне, убедившись в их достоверности и отсутствии возможности манипуляций.')
        },
        {
          id: 1,
          title: this.getTranslation('Доверие'),
          subtitle: this.getTranslation('Блокчейн и смарт-контракты создают надежную среду для игры, в которой доверие между участниками и платформой является основополагающим принципом.')
        },
        {
          id: 2,
          title: this.getTranslation('Безопасность'),
          subtitle: this.getTranslation('Благодаря технологии блокчейн, данные о розыгрышах хранятся безопасно и надежно, защищая их от взлома или изменения.')
        },
      ];
    },

    mainInformation() {
      return [
        this.getTranslation('Мы представляем инновационную платформу лотерей, основанную на <span>блокчейн-технологии и смарт-контрактах,</span> которая обеспечивает абсолютную прозрачность и непреложную достоверность каждого розыгрыша.'),
        this.getTranslation('Честность и прозрачность всегда были основополагающими принципами любой лотереи, и благодаря технологии блокчейн и смарт-контрактам <span>мы делаем этот принцип более доступным</span> и явным для каждого игрока.'),
        this.getTranslation('Каждый розыгрыш на <span>Cryptolot</span> записывается в блокчейн, создавая неизменяемую цепь данных о каждом этапе процесса - от участия игроков до определения победителей. Смарт-контракты, программированные для выполнения определенных правил и условий лотереи, гарантируют, что <span>ни один человек, даже администраторы платформы</span>, не могут изменить результаты или вмешаться в процесс розыгрыша.')
      ];
    }
  },
  beforeRouteLeave(to, from, next) {
    to.query.previousPath = from.path;
    next();
  },
  components: {
    FaqDetails,
    InfoCarousel,
    PossibilitiesBlock,
    Button,
    WinnerBlock,
    MainCardGame,
  },
}
</script>

<style lang="scss" scoped>
@import "main";
</style>