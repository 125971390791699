import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as Sentry from "@sentry/vue";
import clickOutsideDirective from "./directives/ClickOutsideDirective";

import "./assets/scss/main.scss";

createApp(App)
  .use(store)
  .use(router)
  .directive("click-outside", clickOutsideDirective)
  .mount("#app");

Sentry.init({
  dsn: "https://c73b22ad20282b84a5c1680a6218afe2@o4506920882339840.ingest.us.sentry.io/4506920883716096",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
