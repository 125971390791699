import {getAsync, postAsync, putAsync} from './config.js'

// GET REQUESTS

export async function getGames() {
  return await getAsync('/api/v1/games')
}

export async function getGame(id) {
  return await getAsync(`/api/v1/games/${id}`)
}

export async function getRooms() {
  return await getAsync('/api/v1/games/rooms')
}

export async function getRoom(id) {
  return await getAsync(`/api/v1/games/rooms/${id}`)
}

export async function getActiveRooms() {
  return await getAsync('/api/v1/games/rooms/active')
}

export async function getWallet() {
  return await getAsync('/api/v1/wallet')
}

export async function getAllTransaction(data) {
  return await getAsync('/api/v1/payments', data)
}

export async function getPaymentsTypes() {
  return await getAsync('/api/v1/payments/types')
}

export async function getPaymentsHistory(data) {
  return await getAsync('/api/v1/payments/history', data)
}

export async function getTicketUserRoom(id) {
  return await getAsync(`/api/v1/games/rooms/${id}/tickets/my/count`)
}

export async function getLastWinners(data) {
  return await getAsync('/api/v1/games/winners', data)
}

export async function getInfoReferrals() {
  return await getAsync('/api/v1/referral/rewards')
}

export async function faqList() {
  return await getAsync('/api/v1/info/faq')
}

export async function getReferralCod() {
  return await getAsync('/api/v1/referral/code')
}

export async function getHistoryActive() {
  return await getAsync('/api/v1/user/rooms', { active: 1 })
}

export async function getHistoryCompleted() {
  return await getAsync('/api/v1/user/rooms', { active: 0 })
}

export async function getAboutCompany() {
  return await getAsync('/api/v1/info/general/about')
}
export async function getLocales() {
  return await getAsync('/api/v1/info/lang')
}

export async function getTerms() {
  return await getAsync('/api/v1/info/general/terms')
}

export async function getPrivacy() {
  return await getAsync('/api/v1/info/general/privacy')
}

export async function getAllAvailableTickers() {
  return await getAsync('/api/v1/payments/tickers')
}

export async function getDataWithdrawal() {
  return await getAsync('/api/v1/payments/tickers', { type: 'withdrawal' })
}

export async function getWithdrawalInfo(data) {
  return await getAsync('/api/v1/payments/withdrawal/info', data)
}

export async function oauthCallback(provider, data) {
  if(typeof data == 'object') return await getAsync(`/api/v1/login/oauth/${provider}/callback`, data);
  if(typeof data == 'string') return await getAsync(`/api/v1/login/oauth/${provider}/callback?` + data)
}

export async function getFortuneWinners() {
  return await getAsync('/api/v1/fortune/winners')
}

export async function getFortuneSection() {
  return await getAsync('/api/v1/fortune/section')
}


// PUT REQUESTS
// PUT REQUESTS
// PUT REQUESTS

export async function buyTicketFortune(data) {
  return await putAsync('/api/v1/fortune/ticket/buy', data)
}

export async function playFortune(data) {
  return await putAsync('/api/v1/fortune/play', data)
}

// POST REQUESTS
// POST REQUESTS
// POST REQUESTS

export async function registration(data) {
  return await postAsync('/api/v1/register', data)
}

export async function login(data) {
  return await postAsync('/api/v1/login', data)
}

export async function buyTicket({ id, count }) {
  return await postAsync(`/api/v1/games/rooms/${id}/tickets/buy`, count)
}

export async function changeAvatar(avatar) {
  return await postAsync('/api/v1/settings/avatar', avatar, true)
}

export async function changeUsername(username) {
  return await postAsync('/api/v1/settings/username', username)
}

export async function changeEmail(email) {
  return await postAsync('/api/v1/settings/email', email)
}

export async function changePassword(password) {
  return await postAsync('/api/v1/settings/password', password)
}

export async function secretKey2FA() {
  return await postAsync('/api/v1/settings/2fa/google/generate')
}

export async function enable2FA(data) {
  return await postAsync('/api/v1/settings/2fa/google/enable', data)
}

export async function login2FA(data) {
  return await postAsync('/api/v1/login/2fa/google', data)
}

export async function sendResetEmail(data) {
  return await postAsync('/api/v1/password/forgot', data)
}

export async function createWithdrawalPayment(data) {
  return await postAsync('/api/v1/payments/withdrawal/create', data)
}

export async function createDepositPayment(data) {
  return await postAsync('/api/v1/payments/deposit/create', data)
}

export async function disable2FA(data) {
  return await postAsync('/api/v1/settings/2fa/google/disable', data)
}
