<template>
  <div class="referral-item">
    <div class="referral-item__refill">
      <img src="@/assets/svg/referral-back.svg" alt="">
      <div>
        <span>{{ referral.source_transaction?.type }}</span>
        <span>{{ referral.source_transaction?.value }}$</span>
      </div>
    </div>
    <div class="referral-item__income">
      <span>{{getTranslation('Ваш доход')}}</span>
      <span>{{ referral.transaction?.value }}$</span>
    </div>
    <div class="referral-item__date">
      <span>{{ referral.source_transaction?.date }}</span>
    </div>
    <div class="referral-item__user">
      <span class="referral-item__name">{{ referral.referral_user?.username }}</span>
      <div class="referral-item__avatar">
        <img :src="referral.referral_user?.avatar" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    referral: {
      type: Object,
    }
  },
   computed:{
     ...mapGetters('locale', ['getTranslation'])
   }
}
</script>

<style lang="scss" scoped>
@import "referralItem";
</style>