<template>
  <div class="language-switcher">
    <Button
      class="language-switcher__btn"
      v-if="!isSwitcherOppened"
      @click="changeSwitcher"
      color="dark"
    >
      <img
        class="language-switcher__img"
        :src="getSelectedLanguageIcon()"
        alt=""
        width="40px"
        height="22px"
        srcset=""
      />
      <img
        class="language-switcher__img-arrow"
        src="@/assets/svg/arrow_down.svg"
        alt=""
        srcset=""
      />
    </Button>
    <div v-else v-click-outside="closeSwitcher">
      <div class="language-selector">
        <Button
          @click="changeSwitcher"
          color="dark"
          class="language-switcher__btn"
        >
          <img
            class="language-switcher__img"
            :src="getSelectedLanguageIcon()"
            alt=""
            width="40px"
            height="22px"
            srcset=""
          />
          <img
            src="@/assets/svg/arrow_down.svg"
            style="rotate: 180deg"
            alt=""
            srcset=""
          />
        </Button>

        <div class="language-switcher__dropdown-block">
          <div class="language-switcher__selector-decorations">
            <div class="language-switcher__selector-decoration">
              <svg
                width="33"
                height="33"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 19V6.14286L6.14286 1H19"
                  stroke="#161F2D"
                  stroke-width="1.3"
                />
              </svg>
            </div>
            <div class="language-switcher__selector-decoration">
              <svg
                width="33"
                height="33"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 19V6.14286L6.14286 1H19"
                  stroke="#161F2D"
                  stroke-width="1.3"
                />
              </svg>
            </div>
          </div>
          <ul class="language-switcher__dropdown">
            <li @click="changeSwitcher">
              Выбор языка
              <div class="language-switcher__dropdown-images">
                <img
                  class="language-switcher__img"
                  :src="getSelectedLanguageIcon()"
                  alt=""
                  width="40px"
                  height="22px"
                  srcset=""
                />
                <img src="@/assets/svg/arrow_down.svg" alt="" srcset="" />
              </div>
            </li>

            <li v-for="lang in locale" @click="changeLanguage(lang.title)">
              <img :src="lang.image" />
              <span>{{ lang.name }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from "@/components/UI/Button/Button.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "LanguageSwitcher",
  components: { Button },
  data() {
    return {
      isSwitcherOppened: false,
    };
  },
  methods: {
    changeSwitcher() {
      event.stopPropagation();
      this.isSwitcherOppened = !this.isSwitcherOppened;
    },

    changeLanguage(title) {
      this.$store.dispatch("locale/setCurrentLocale", title);
      this.isSwitcherOppened = !this.isSwitcherOppened;
    },
    closeSwitcher() {
      this.isSwitcherOppened = false;
    },
  },
  computed: {
    ...mapGetters("locale", ["getSelectedLanguageIcon"]),
    ...mapState("locale", ["locale"]),
  },
};
</script>

<style lang="scss" scoped>
@import "languageSwitcher";
</style>
