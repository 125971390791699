<template>
  <div class="lottery">
    <div class="_container">
      <div class="lottery__wrapper">
        <div class="lottery__header">
          <div class="lottery__head">
            <ButtonBack @click="backRoute" />
            <div>
              <h2 class="lottery__title">{{ room.game?.title }}</h2>
            </div>
          </div>
          <div class="lottery__transaction">
            <span>{{getTranslation('Смарт контракт лотереи')}}</span>
            <a :href="room.game?.web3_contract" target="_blank">
              {{ room.game?.web3_contract }}
            </a>
          </div>
        </div>
        <div class="lottery__container">
          <div class="lottery__mobile">
            <div class="lottery__head-mobile">
              <div>
                <h2 class="lottery__title">{{ room.game?.title }}</h2>
                <h4 class="lottery__id">{{ room.game?.id }}</h4>
              </div>
              <ButtonBack @click="backRoute" />
            </div>
            <LotteryCard
                :room="room"
            />
          </div>
          <div class="lottery__block">
            <div class="lottery__block-head">
              <h3 class="lottery__block-title">{{getTranslation('Участники лотереи')}}</h3>
              <div>
                <span>{{ room?.participants_count }} {{ participantsCountText }}</span>
                {{getTranslation('участвуют прямо сейчас')}}
              </div>
            </div>
            <div v-if="room?.participants" class="lottery__member-list">
              <LotteryMember
                  v-for="participant in room?.participants"
                  :participant="participant"
              />
            </div>
            <div v-if="room.participants?.length === 0" class="lottery__member-empty">
              <img src="@/assets/image/wave-empty-container.png" alt="">
              <span>{{getTranslation('Еще никто не учавствует')}}</span>
            </div>
          </div>
        </div>
        <div class="lottery__footer">
          {{ room.game?.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AvailableCard from "@/components/AvailableCard/AvailableCard.vue";
import LotteryMember from "@/components/LotteryMember/LotteryMember.vue";
import ButtonBack from "@/components/UI/ButtonBack/ButtonBack.vue";
import LotteryCard from "@/components/LotteryCard/LotteryCard.vue";
import {mapState, mapGetters} from "vuex";
export default {
  data() {
    return {
      path: ''
    }
  },
  methods: {
    backRoute() {
      if (this.path) {
        this.$router.push(this?.path)
      } else {
        this.$router.push('/dashboard/available-lotteries')
      }
    },
    startRequestInterval() {
      this.intervalId = setInterval(() => {
        this.$store.dispatch('rooms/getRoom', +this.$route.params.id)
      }, 5000);
    },
    stopRequestInterval() {
      clearInterval(this.intervalId);
    }
  },
  mounted() {
    this.$store.dispatch('rooms/getRoom', +this.$route.params.id)
  },
  computed: {
    ...mapState('rooms', ['room']),
    ...mapGetters('locale', ['getTranslation']),
    
    participantsCountText () {
      let participantsCount = this.room.participants_count

      if (participantsCount === 1) {
        return 'пользователь'
      } else if (participantsCount >=2 && participantsCount <= 4) {
        return 'пользователя'
      } else {
        return 'пользователей'
      }
    }
  },
  created() {
    this.path = this.$route.query.previousPath;

    this.startRequestInterval();
  },
  beforeUnmount() {
    this.stopRequestInterval();
  },
  components: {
    LotteryCard,
    ButtonBack,
    LotteryMember,
    AvailableCard
  }
}
</script>

<style lang="scss" scoped>
@import "lottery";
</style>