<template>
  <div
      class="dashboard-burger"
      :class="{ 'open': isBurger }"
  >
    <div class="_container">
      <div class="dashboard-burger__container">
        <DashboardTabs
            @close-burger="$emit('close-burger')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/UI/Button/Button.vue";
import DashboardTabs from "@/components/UI/DashboardTabs/DashboardTabs.vue";

export default {
  props: {
    isBurger: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Button,
    DashboardTabs,
  }
}
</script>

<style lang="scss" scoped>
@import "dashboardBurger";
</style>