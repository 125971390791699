<template>
  <main class="fortune-wheel">
    <div class="_container">
      <div class="fortune-wheel__body">
        <div class="fortune-wheel__left">
          <div class="fortune-wheel__left-bg" />
          <div class="fortune-wheel__container">
            <div class="fortune-wheel__list">
              <FortuneWheelItem
                v-for="(winner, idx) in fortuneWinners"
                :key="idx"
                :winner="winner"
              />
            </div>
          </div>
        </div>
        <div class="fortune-wheel__right">
          <Wheel @open-prize="openPrize" />
        </div>
      </div>
    </div>
  </main>
  <ModalPrize
    v-if="prize"
    :prize="prize"
    @close-modal="closeModal"
  />
</template>

<script>
import Wheel from "@/components/Wheel/Wheel.vue";
import FortuneWheelItem from "@/components/FortuneWheelItem/FortuneWheelItem.vue";
import ModalPrize from "@/components/Modals/ModalPrize/ModalPrize.vue";
import { mapState } from "vuex";

export default {
  data() {
    return {
      prize: '',
    }
  },
  methods: {
    openPrize(prize) {
      this.prize = prize?.name ? prize.name : 'Произошла ошибка'
    },
    closeModal() {
      this.prize = ''
    },
  },
  mounted() {
    this.$store.dispatch('fortuneWheel/getFortuneWinners')
  },
  computed: {
    ...mapState('fortuneWheel', ['fortuneWinners'])
  },
  components: {
    ModalPrize,
    FortuneWheelItem,
    Wheel
  },
}
</script>

<style lang="scss" scoped>
@import "FortuneWheelPage";
</style>