<template>
  <div class="wheel">
    <div class="wheel__container">
      <FortuneWheel
        ref="wheel"
        class="wheel__main"
        :class="{ 'wheel-rotate': wheelRotate }"
        :canvas="canvasOptions"
        :prizes="prizesCanvas"
        :prizeId="prizeId"
        :key="prizesCanvas.length"
        @rotateStart="onRotateStart"
        @rotateEnd="onRotateEnd"
      />
      <img class="wheel__arrow" src="@/assets/image/arrow-wheel.png" alt="">
      <span class="wheel__number">
        {{ rotate ? fortuneSections?.active_tickets_count :
           fortuneSections?.active_tickets_count > 0 ?
           fortuneSections?.active_tickets_count - 1 || 0 :
           fortuneSections?.active_tickets_count || 0 }}
      </span>
    </div>
    <div :class="{ 'remove-button': !rotate }">
      <div
        class="wheel__button"
        @click="startRotate"
      >
        {{ fortuneSections?.active_tickets_count >= 1 ? getTranslation('Free Spin') : 'Spin for ' + fortuneSections?.ticket_price + ' USDT' }}
      </div>
    </div>
    <span class="wheel__text">{{ getTranslation('1 Friend = 1 spin for you and for your friend') }}</span>
  </div>
</template>

<script>
import FortuneWheel from 'vue-fortune-wheel'
import 'vue-fortune-wheel/style.css'
import {mapGetters, mapState} from "vuex";
import {login} from "@/api";
import {transform} from "vue-agile/jest.config";

export default {
  components: {
    FortuneWheel
  },
  data() {
    return {
      rotate: false,
      wheelRotate: true,
      prizeId: null,
      canvasOptions: {
        borderWidth: '20',
        borderColor: '#0973aa',
        btnText: '',
        btnWidth: 0,
        fontSize: '24',
        textDirection: 'vertical',
        textLength: 12
      },
      prizesCanvas: [
        { id: 1, name: "Loading...", probability: 50, bgColor: '#45ace9', color: '#ffffff' },
        { id: 2, name: "Please wait", probability: 50, bgColor: '#ffffff', color: '#45ace9' }
      ],
      // prizesCanvas: [
      //   {
      //     id: 1,
      //     name: '1$',
      //     value: '5$ value',
      //     bgColor: '#45ace9',
      //     color: '#ffffff',
      //     probability: 10
      //   },
      //   {
      //     id: 2,
      //     name: '2$',
      //     value: '5$ value',
      //     bgColor: '#ffffff',
      //     color: '#45ace9',
      //     probability: 10
      //   },
      //   {
      //     id: 3,
      //     name: '5$',
      //     value: '5$ value',
      //     bgColor: '#45ace9',
      //     color: '#ffffff',
      //     probability: 10
      //   },
      //   {
      //     id: 4,
      //     name: '10$',
      //     value: '5$ value',
      //     bgColor: '#ffffff',
      //     color: '#45ace9',
      //     probability: 10
      //   },
      //   {
      //     id: 5,
      //     name: '15$',
      //     value: '5$ value',
      //     bgColor: '#45ace9',
      //     color: '#ffffff',
      //     probability: 10
      //   },
      //   {
      //     id: 6,
      //     name: '25$',
      //     value: '5$ value',
      //     bgColor: '#ffffff',
      //     color: '#45ace9',
      //     probability: 10
      //   },
      //   {
      //     id: 7,
      //     name: '1 Ticket',
      //     value: '5$ value',
      //     bgColor: '#45ace9',
      //     color: '#ffffff',
      //     probability: 10
      //   },
      //   {
      //     id: 8,
      //     name: '50$',
      //     value: '5$ value',
      //     bgColor: '#ffffff',
      //     color: '#45ace9',
      //     probability: 10
      //   },
      //   {
      //     id: 9,
      //     name: '75$',
      //     value: '5$ value',
      //     bgColor: '#45ace9',
      //     color: '#ffffff',
      //     probability: 10
      //   },
      //   {
      //     id: 10,
      //     name: '100$',
      //     value: '5$ value',
      //     bgColor: '#ffffff',
      //     color: '#45ace9',
      //     probability: 10
      //   },
      // ]
    };
  },
  methods: {
    startRotate() {
      if (this.fortuneSections?.active_tickets_count === 0) {
        this.$store.dispatch('fortuneWheel/buyTicketFortune')

        setTimeout(() => {
          if (this.buyTicket) {
            this.$refs.wheel.startRotate()
            this.$store.dispatch('fortuneWheel/playFortune')
          }
        }, 1000)
      } else {
        this.$refs.wheel.startRotate()
        this.$store.dispatch('fortuneWheel/playFortune')
      }

      setTimeout(() => {
        this.prizeId = this.playWin?.data?.id
      }, 2000)
    },
    onRotateStart() {
      this.rotate = false
    },
    onRotateEnd(prize) {
      this.rotate = true
      this.$emit('open-prize', prize)

      this.$store.dispatch('fortuneWheel/getFortuneSection')
      this.$store.dispatch('fortuneWheel/getFortuneWinners')
      this.$store.dispatch("wallet/getWallet")
    },
    updatePrizesCanvas() {
      this.prizesCanvas = this.fortuneSections.sections.map((item, index) => ({
        id: item.id,
        name: item.name,
        probability: item.chance,
        bgColor: index % 2 === 0 ? '#ffffff' : '#45ace9',
        color: index % 2 === 0 ? '#45ace9' : '#ffffff'
      }))

      this.wheelRotate = false
      this.rotate = true
    }
  },
  mounted() {
    this.$store.dispatch('fortuneWheel/getFortuneSection').then(() => {
      setTimeout(() => {
        this.updatePrizesCanvas()
      }, 1500)
    })
  },
  computed: {
    ...mapState('fortuneWheel', ['fortuneSections']),
    ...mapState('fortuneWheel', ['playWin']),
    ...mapState('fortuneWheel', ['buyTicket']),
    ...mapGetters('locale', ['getTranslation'])
  }
}
</script>

<style scoped lang="scss">
@import "wheel";

.wheel-rotate {
  transform: rotate(0deg) !important;

  @media (max-width: 576px) {
    transform: translateX(50px) rotate(0deg) !important;
  }
}

.remove-button {
  pointer-events: none;
  cursor: auto;
  opacity: 0;
}
</style>