<template>
  <div class="confirm-code">
    <h2 class="confirm-code__title">{{getTranslation('Код подтверждения')}}</h2>
    <div class="confirm-code__container">
      <div class="confirm-code__block">
        <label for="text">{{getTranslation('Код подтверждения электронной почты')}}</label>
        <Input
            :type="'email'"
            :id="'email'"
            :placeholder="getTranslation('Пожалуйста, введите код')"
            :auth="'auth'"
        />
      </div>
      <Button>
        {{getTranslation('Подтвердить')}}
      </Button>
      <div class="confirm-code__under">
        <router-link to="#">{{getTranslation('Не получили код?')}}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/UI/Input/Input.vue";
import Button from "@/components/UI/Button/Button.vue";
import {mapGetters} from "vuex";

export default {
  components: {Button, Input},
  computed:{
    ...mapGetters('locale', ['getTranslation'])
  }

}
</script>

<style lang="scss" scoped>
@import "confirmCode";
</style>