import * as API from '../../api/index'

export default {
  namespaced: true,
  state() {
    return {
      referralsInfo: [],
      referralCod: null,
    }
  },
  actions: {
    getInfoReferrals({ state }) {
      API.getInfoReferrals().then(response => {
        state.referralsInfo = response
      })
    },
    getReferralCod({ state }) {
      API.getReferralCod().then(response => {
        state.referralCod = response
      })
    }
  },
  mutations: {

  },
  getters: {

  },
}
