<template>
  <div
      v-for="item in howWorks"
      :key="item.id"
      class="how-work-block"
  >
    <div class="how-work-block__circle">
      <span>{{ item.id + 1 }}</span>
      <img src="@/assets/image/dots.png" alt="">
      <div class="how-work-block__circle-blur"></div>
    </div>
    <p v-html="item.title"></p>
    <img class="how-work-block__corner how-work-block__corner--left" src="@/assets/svg/corner-how-work.svg" alt="">
    <img class="how-work-block__corner how-work-block__corner--right" src="@/assets/svg/corner-how-work.svg" alt="">
  </div>
</template>

<script>
export default {
  props: {
    howWorks: {
      type: Array,
      default: [],
    }
  }
}
</script>

<style lang="scss" scoped>
@import "howWorkBlock";
</style>