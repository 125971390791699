<template>
  <div class="new-password">
    <h2 class="new-password__title">{{getTranslation('Новый пароль')}}</h2>
    <div class="new-password__container">
      <div class="new-password__block">
        <label for="text">{{getTranslation('Установите пароль')}}</label>
        <Input
            :type="'email'"
            :id="'email'"
            :placeholder="getTranslation('Пожалуйста, установите новый пароль')"
            :auth="'auth'"
        />
      </div>
      <Button>
        {{getTranslation('Войти по новому паролю')}}
      </Button>
    </div>
  </div>
</template>

<script>
import Input from "@/components/UI/Input/Input.vue";
import Button from "@/components/UI/Button/Button.vue";
import {mapGetters} from "vuex";

export default {
  components: {Button, Input},
  computed:{
    ...mapGetters('locale', ['getTranslation'])
  }
}
</script>

<style lang="scss" scoped>
@import "newPassword";
</style>