import * as API from '../../../api'

export default {
  namespaced: true,
  state() {
    return {
      depositInformation: null,
      expirationDate: null,
      countdownInterval: null,
      countdownTime: null,
    }
  },
  actions: {
    createDepositPayment({ state, commit, dispatch } , data) {
      API.createDepositPayment(data).then(response => {
        if (response) {
          commit('setDepositInformation', response)
          commit('setExpirationDate', response[0].expired_at)
          commit('startCountdown')
          dispatch('wallet/getWallet', null, { root: true })
        }
      })
    },
  },
  mutations: {
    setDepositInformation(state, depositInformation) {
      state.depositInformation = depositInformation
    },
    setExpirationDate(state, expirationDate) {
      state.expirationDate = expirationDate
    },
    startCountdown(state) {
      const utcDate = new Date().toUTCString()
      const currentDate = new Date(utcDate).getTime()

      state.countdownTime = new Date(state.expirationDate).getTime() - currentDate

      state.countdownInterval = setInterval(() => {
        state.countdownTime -= 1000
      }, 1000)
    },
    stopCountdown(state) {
      clearInterval(state.countdownInterval)
      state.countdownTime = 0
    },
  },
  getters: {
    formattedCountdownTime(state) {
      if (state.countdownTime === null || state.countdownTime <= 0) {
        return '00:00:00'
      }
      const hours = Math.floor(state.countdownTime / (1000 * 60 * 60))
      const minutes = Math.floor((state.countdownTime % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((state.countdownTime % (1000 * 60)) / 1000)
      return `${hours.toString()}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    }
  },
}
