<template>
  <div class="faq-details">
    <div
        @click="openDetails = !openDetails"
        class="faq-details__summary"
    >
      <div class="_container">
        <div class="faq-details__block">
          <span>{{ faq?.question }}</span>
          <div class="faq-details__button">
            <img :class="{ 'open': openDetails }" src="../../../assets/svg/arrow-right.svg" alt="arrow">
          </div>
        </div>
      </div>
    </div>
    <div
        :class="{ 'open': openDetails }"
        class="faq-details__collapse"
    >
      <div class="_container faq-details__collapse-block">
        {{ faq?.answer }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    faq: Object,
  },
  data() {
    return {
      openDetails: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "faqDetails";
</style>