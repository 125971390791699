export default {
  mounted(el, binding) {
    const listener = (event) => {
      if (!el.contains(event.target) && !event.defaultPrevented) {
        binding.value(event);
      }
    };
    el.__vueClickOutside__ = listener;
    document.addEventListener("click", listener);
  },
  unmounted(el) {
    document.removeEventListener("click", el.__vueClickOutside__);
    delete el.__vueClickOutside__;
  },
};
