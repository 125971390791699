import * as API from '../../api/index'

export default {
    namespaced: true,
    state() {
        return {
            wallet: {},
        }
    },
    actions: {
        getWallet({ state }) {
            API.getWallet().then(response => {
                state.wallet = response?.data
            })
        },
    },
    mutations: {

    },
    getters: {

    },
}
