<template>
  <div
      class="card-game"
      :class="{ 'soon': game?.is_available_soon }"
  >
    <div v-if="game?.is_available_soon" class="card-game__soon">
      <span>{{getTranslation('Скоро будет')}}</span>
      <img src="@/assets/svg/soon-rectangle.svg" alt="">
    </div>
    <div class="card-game__bg">
      <img :src="game.image" alt="">
      <div></div>
    </div>
    <div class="card-game__block">
      <div class="card-game__link">
        <span>{{ game.title }}</span>
        <img src="../../assets/svg/arrow-right.svg" alt="">
      </div>
      <p class="card-game__text">{{ game.description }}</p>
    </div>
    <div class="card-game__blur"></div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  props: {
    game: {
      type: Object,
    }
  },
  computed:{
    ...mapGetters('locale', ['getTranslation'])
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
@import "mainCardGame";
</style>