<template>
  <div class="_container">
    <header :class="layout" class="header">
      <Logo @click="$router.push('/')" />
      <nav class="header__nav">
        <router-link to="/" class="header__link">{{
          getTranslation("Главная")
        }}</router-link>
        <div @click="$emit('open-about-company')" class="header__link">
          {{ getTranslation("О компании") }}
        </div>
      </nav>
      <div class="header__buttons">
        <Button @click="openRegistration">
          {{ getTranslation("Начать игру") }}
        </Button>
        <Button @click="openLogin" color="dark">
          {{ getTranslation("Войти") }}

          <img src="@/assets/svg/gridicons_sign-out.svg" alt="" />
        </Button>
        <LanguageSwitcher />
      </div>
      <div class="header__mobile-buttons">
        <LanguageSwitcher />

        <button
          @click="changeBurger"
          :class="{ open: isBurger }"
          class="header__burger"
        >
          <div></div>
          <div></div>
          <div></div>
        </button>
      </div>
    </header>
    <img
      :class="layout"
      class="header__coin"
      src="@/assets/image/coin_bg-right.png"
      alt="coin"
    />
  </div>
</template>

<script>
import Button from "@/components/UI/Button/Button.vue";
import Logo from "@/components/Logo/Logo.vue";
import { mapGetters } from "vuex";
import { login } from "@/api";
import LanguageSwitcher from "@/components/LanguageSwitcher/LanguageSwitcher.vue";

export default {
  props: {
    layout: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isBurger: false,
    };
  },
  methods: {
    changeBurger() {
      event.stopPropagation();
      this.isBurger = !this.isBurger;
      this.$emit("toggle-burger");
    },
    openRegistration() {
      if (this.isAuthenticated) {
        this.$router.push("/dashboard/available-lotteries");
      } else {
        this.$router.push("/auth/registration");
      }
    },
    openLogin() {
      if (this.isAuthenticated) {
        this.$router.push("/dashboard/available-lotteries");
      } else {
        this.$router.push("/auth/authorization");
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapGetters("locale", ["getTranslation"]),
  },
  components: {
    LanguageSwitcher,
    Logo,
    Button,
  },
};
</script>

<style lang="scss" scoped>
@import "mainHeader";
</style>
